import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  display: grid;
  padding: 10vh 0;
  justify-items: center;

  ${breakpoints.xs`
    grid-template-rows: 100px 1fr;
  `}
  ${breakpoints.lg`
    grid-template-rows: 200px 1fr;
    padding: 10vh 0;
  `}
  ${breakpoints.xl`
    padding: 10vh 10vw 10vh;
  `}
`;

const ProjectCardsContainer = styled.div`
  ${breakpoints.xs`
    row-gap: 80px;
  `}
  ${breakpoints.md`
    row-gap: 80px;
  `}
  ${breakpoints.lg`
    row-gap: 98px;
  `}
  display: flex;
  flex-direction: column;
`;

const ProjectCategories = styled.ul`
  display: flex;
  wrap: nowrap;
  overflow-x: auto;
  cursor: default;
  -ms-overflow-style: none;
  scrollbar-width:none;
  ::-webkit-scrollbar {
    display: none;
  }
  max-width: 80%;
  ${breakpoints.xs`
    gap: 16px;
    margin: 20px 0 68px;
  `}
  ${breakpoints.md`
    gap: 24px;
    margin: 0 0 128px;
  `}
  ${breakpoints.lg`
    gap: 24px;
  `}
`;

const Category = styled.li<{isActive: boolean}>`
  font-size: 18px;
  font-family: ${({ theme }) => theme.typography.akkurat};
  color: ${({ theme }) => theme.color.basic.black};
  border-bottom: ${({ theme, isActive }) => isActive && `3px ${theme.color.hamam.shade} solid`};
  padding-bottom: 3px;
  font-weight: ${({ isActive }) => isActive && `bolder`};
  letter-spacing: 0.26px;
  cursor: pointer;
`;

export {
  Container,
  ProjectCardsContainer,
  ProjectCategories,
  Category,
};
