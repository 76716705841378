import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';

import breakpoints from '../../styles/breakpoints';
import { ButtonOutlined } from '../buttons';

const MoreInfoButton = styled(ButtonOutlined)`
  ${breakpoints.xs`
    margin-bottom: 30px;
  `}
  ${breakpoints.lg`
    display: none;
  `}
`;

const ProjectCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  ${breakpoints.xs`
    width: 300px;
    min-height: unset;
    border-radius: unset;
    `}
  ${breakpoints.md`
    width: 500px;
    min-height: unset;
    border-radius: unset;
    `}
  ${breakpoints.lg`
    border-radius: 10px;
    width: 940px;
    min-height: 600px;
    box-shadow: 0 2px 10px 1px rgba(57, 116, 128, 0.3);
    background-image: linear-gradient(243deg, #fdfdfd, rgba(250, 250, 250, 0.3));
  `}
`;

const ProjectCardCoverContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  ${breakpoints.xs`
    border-radius: 15px;
    height: 200px;
    margin-bottom: 16px;
  `}
  ${breakpoints.md`
    border-radius: 15px;
    height: 160px;
    margin-bottom: 16px;
  `}
  ${breakpoints.lg`
    height: 180px;
    border-radius: unset;
    margin-bottom: 28px;
  `}
`;

const ProjectCardImg = styled(GatsbyImage) < { image: IGatsbyImageData } >`
  object-fit: cover;
  height: 100%;
`;

const ProjectCardLogo = styled.img < { src: string } >`
    position: absolute;
  ${breakpoints.xs`
    max-width: 75px;
    height: 35px;
    top: 15px;
    left: 15px;
  `}
  ${breakpoints.md`
    max-width: 90px;
    height: 35px;
    top: 10px;
    left: 20px;
  `}
  ${breakpoints.lg`
    max-width: 148px;
    height: 35px;
    top: 20px;
    left: 30px;
  `}
`;

const ContentWrapper = styled.div`
  ${breakpoints.xs`
    margin: 0 0;
  `}
  ${breakpoints.md`
    margin: 0 0;
  `}
  ${breakpoints.lg`
    margin: 0px 32px 32px 32px;
  `}
`;

const PlatformtagContainer = styled.ul`
  display: flex;
  column-gap: 20px;
`;

const PlatformTag = styled.li`
  display: inline-block;
  padding-bottom: 11px;
  border: solid ${({ theme }) => theme.color.hamam.shade};
  border-width: 0 0 2px;
  margin-bottom: 17px;
`;

const PlatformText = styled.p`
  color: ${({ theme }) => theme.color.hamam.shade};
  font-family: ${({ theme }) => theme.typography.mosk.medium};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.23px;
`;

const ProjectCardTitle = styled.h2`
  font-family: ${({ theme }) => theme.typography.mosk.bold};
  color: ${({ theme }) => theme.color.basic.black};
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-right: 10px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
  ${breakpoints.xs`
    font-size: 30px;
    margin-bottom: 12px;
  `}
  ${breakpoints.md`
    font-size: 30px;
  `}
  ${breakpoints.lg`
    font-size: 32px;
  `}
`;

const ProjectCardDescription = styled.p`
    margin-top: 15px;
    margin-bottom: 26px;
    font-family: ${({ theme }) => theme.typography.akkurat};
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.color.basic.black};
    ${breakpoints.xs`
      margin-bottom: 16px;
  `}
  `;

const ProjectCardTechnologiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  ${breakpoints.xs`
      position: relative;
  `}
  ${breakpoints.md`
    position: relative;
  `}
  ${breakpoints.lg`
    position: absolute;
    bottom: 32px;
    left: 32px;
    margin: 0;
  `}
`;

const ProjectCardTechnologiesHeader = styled.h3`
  display: block;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.23px;
  color: ${({ theme }) => theme.color.basic.black};
  font-family: ${({ theme }) => theme.typography.mosk.medium};
  ${breakpoints.xs`
    margin-bottom: 12px;
  `}
  ${breakpoints.md`
    margin-bottom: 16px;
  `}
`;

const ProjectCardTechnologiesWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const ProjectCardTechnologyTagsWrapper = styled.li < { key: string } >`
  position: relative;
  padding: 5px 8px;
  display: inline-block;
  border-radius: 5px;
`;

const PlatformBackground = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.hamam.tint};
  opacity: 0.3;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
`;

const ProjectCardTechnologyTag = styled.span`
  color: ${({ theme }) => theme.color.hamam.shade};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.23px;
  font-family: ${({ theme }) => theme.typography.akkurat};
`;

const MoreInfoLink = styled.span`
  ${breakpoints.lg`
    font-family: ${({ theme }) => theme.typography.akkurat};
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.22px;
    color: ${({ theme }) => theme.color.hamam.shade};
    text-transform: uppercase;
  `}
`;

const Arrow = styled.img`
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: -2px;
  margin-left: 4px;
`;

const MoreInfoContainer = styled.div`
  ${breakpoints.xs`
    display: none;
  `}
  ${breakpoints.lg`
    display: unset;
    position: absolute;
    bottom: 32px;
    right: 32px;
  `}
  cursor: pointer;
`;

export {
  Arrow,
  ContentWrapper,
  MoreInfoButton,
  MoreInfoLink,
  MoreInfoContainer,
  ProjectCardContainer,
  ProjectCardCoverContainer,
  ProjectCardLogo,
  ProjectCardImg,
  PlatformtagContainer,
  PlatformTag,
  PlatformText,
  ProjectCardTitle,
  ProjectCardDescription,
  ProjectCardTechnologiesContainer,
  ProjectCardTechnologiesHeader,
  ProjectCardTechnologiesWrapper,
  ProjectCardTechnologyTagsWrapper,
  PlatformBackground,
  ProjectCardTechnologyTag,
};
