/* eslint-disable max-len */
import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { BackgroundSilver, BackgroundDarken } from '../../components/components';
import { PageHeadline, SmallHeadline } from '../../components/headlines/index';
import ProjectCard from '../../components/project-card/project-card';
import { Container, ProjectCardsContainer, ProjectCategories, Category } from './components';

type QueryData = {
  allContentfulCustomerProjects: {
    nodes: {
      title: string,
      technologies: string[],
      customer: {
        logoImage: {
          file: {
            url: string,
          },
        },
      },
      image: {
        gatsbyImageData: IGatsbyImageData,
      },
      platforms: string[],
      description: {
        description: string,
      },
      link: string,
    }[],
  },
};

const ProjectsOverview: React.FC = () => {
  const [selectedTag, setTag] = useState('Alle');
  const { allContentfulCustomerProjects }: QueryData = useStaticQuery(
    graphql`
      query {
        allContentfulCustomerProjects {
          nodes {
            title
            technologies
            customer {
              logoImage {
                file {
                  url
                }
              }
            }
            image {
              gatsbyImageData
            }
            platforms
            description {
              description
            }
            link
          }
        }
      },
    `,
  );

  const projectsTransformed = allContentfulCustomerProjects.nodes.map((project) => {
    return (
      {
        logo: project.customer.logoImage.file.url,
        image: project.image.gatsbyImageData,
        title: project.title,
        platforms: project.platforms,
        description: project.description.description,
        technologies: project.technologies,
        link: project.link,
      }
    );
  });

  let allCategories: string[] = [];
  allContentfulCustomerProjects.nodes.forEach((project) => {
    allCategories = [...allCategories, ...project.platforms];
  });
  const uniqueCategories = Array.from(new Set(allCategories));

  return (
    <BackgroundSilver>
      <BackgroundDarken>
        <Container>
          <div>
            <PageHeadline>Work</PageHeadline>
            <SmallHeadline>UNSERE REFERENZEN</SmallHeadline>
          </div>
          <ProjectCategories>
            <Category onClick={() => setTag('Alle')} isActive={selectedTag === 'Alle'}>
              Alle
            </Category>
            {uniqueCategories.map((tag) =>
              <Category key={tag} onClick={() => setTag(tag)} isActive={selectedTag === tag}>
                {tag}
              </Category>,
            )}
          </ProjectCategories>
          <ProjectCardsContainer>
            {selectedTag === 'Alle' ? projectsTransformed.map((project) =>
              <ProjectCard key={project.link} project={project} />,
            ) : projectsTransformed.filter((p) => p.platforms.includes(selectedTag)).map((project) =>
              <ProjectCard key={project.link} project={project} />,
            )}
          </ProjectCardsContainer>
        </Container>
      </BackgroundDarken>
    </BackgroundSilver>
  );
};

export default ProjectsOverview;
