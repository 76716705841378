import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import ContactSection from '../containers/contact-section/contact-section';
import Footer from '../components/footer/footer';
import NavBar from '../components/navbar/navbar';
import ProjectsOverview from '../containers/projects-overview/projects-overview';

type WorkPageProps = Record<string, never>;

type QueryData = {
  seoInformation: {
    title: string,
    description: string,
    image: {
      fluid: {
        src: string,
      },
    },
    contentType: string,
  },
};

const WorkPage: React.FC<WorkPageProps> = () => {
  const { seoInformation }: QueryData = useStaticQuery(graphql`
    query {
      seoInformation: contentfulSeo(contentful_id: {eq: "4cOqDi0w7d2RD4RM4Ct9VI"}) {
        title
        description
        image {
          fluid {
            src
          }
        }
        contentType
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title={seoInformation.title}
        description={seoInformation.description}
        image={seoInformation.image.fluid.src}
        contentType={seoInformation.contentType}
      />
      <NavBar />
      <ProjectsOverview />
      <ContactSection trackingId='work-s2-project-inquiry-button' />
      <Footer />
    </Layout>
  );
};

export default WorkPage;
